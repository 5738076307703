import KaskoNewPolisData from "./pages/tg/KaskoNewPolisData";
import OsagoNewPolisData from "./pages/tg/OsagoNewPolisData";
import IflNewPolisData from './pages/tg/IflNewPolisData';
import IpotekaNewPolisData from './pages/tg/IpotekaNewPolisData';
import AdminPage from "./pages/AdminPage";
import InsuranceCompaniesPage from './pages/InsuranceCompaniesPage';
import TicketsPage from './pages/TicketsPage';
import TicketPage from './pages/TicketPage';
import ClientsPage from './pages/ClientsPage';
import ClientPage from './pages/ClientPage';
import UsersPage from "./pages/UsersPage";
import BanksPage from "./pages/BanksPage";
import RegionsPage from "./pages/RegionsPage";

import DistributionPage from "./pages/DistributionPage";
import RequestTypeListPage from "./pages/RequesstTypePage/RequestTypeListPage";
//import { RequestTypesListPage } from "./pages/RequesstTypePage";

import Profile from './pages/tg/Profile';
import MyInsurances from './pages/tg/MyInsurances';
import MyFriends from './pages/tg/MyFriends';
import MyOffers from './pages/tg/MyOffers';
import MyTicketData from './pages/tg/MyTicketData';
import MainMenu from './pages/tg/MainMenu';
import SubMenu from './pages/tg/SubMenu';
import NewPolis from './pages/tg/NewPolis';
import UploadFiles from './pages/tg/UploadFiles';
import PolicyPrivacy from './pages/tg/PolicyPrivacy';

const AppRoutes = [
    {
        path: '/tg/PolicyPrivacy',
        element: <PolicyPrivacy />
    },
    ///tg/Profile/MyInsurances/
    {
        path: '/tg/Profile/MyInsurances/:id/:ticketId',
        element: <MyTicketData />
    },
    {
        path: '/tg/Profile/MyInsurances/:id',
        element: <MyInsurances status={100} />
    },
    {
        path: '/tg/:type/MyInsurances/:id',
        element: <MyInsurances status={100} />
    },
    {
        path: '/tg/Profile/MyFriends/:id',
        element: <MyFriends />
    },
    {
        path: '/tg/Profile/MyOffers/:id',
        element: <MyInsurances />
    },
    {
        path: '/tg/Profile/:id',
        element: <Profile />
    },
    {
        path: '/tg/MainMenu/:id',
        element: <MainMenu />
    },
    {
        path: '/tg/MainMenu',
        element: <MainMenu />
    },
    {
        path: '/tg/NewPolis',
        element: <NewPolis />
    },
    {
        path: '/tg/SubMenu/:id/:page',
        element: <SubMenu />
    },
    {
        path: '/tg/:tg_user_id/:type/new/uploadForm',
        element: <UploadFiles />
    },
    {
        path: '/tg/:tg_user_id/:type/:ticketId/uploadForm',
        element: <UploadFiles />
    },
    {
        path: '/tg/kasko/new',
        element: <KaskoNewPolisData mainMeenu={false}/>
    },
    {
        path: '/tg/kasko/small/new',
        element: <KaskoNewPolisData small={true} mainMeenu={false} />
    },
    {
        path: '/tg/kasko/insurances/:id',
        element: <MyInsurances insuranceType={0} />
    },
    {
        path: '/tg/osago/new',
        element: <OsagoNewPolisData />
    },
    {
        path: '/tg/osago/small/new',
        element: <OsagoNewPolisData small={true} />
    },
    {
        path: '/tg/osago/insurances/:id',
        element: <MyInsurances insuranceType={1} />
    },
    {
        path: '/tg/ifl/new',
        element: <IflNewPolisData />
    },
    {
        path: '/tg/ifl/insurances/:id',
        element: <MyInsurances insuranceType={2} />
    },
    {
        path: '/tg/ipoteka/new',
        element: <IpotekaNewPolisData />
    },
    {
        path: '/tg/ipoteka/insurances/:id',
        element: <MyInsurances insuranceType={3} />
    },
    {
        path: '/settings-page',
        element: <AdminPage />
    },
    {
        path: '/settings/insurance-companies-page',
        element: <InsuranceCompaniesPage />
    },
    {
        path: '/settings/banks-page',
        element: <BanksPage />
    },
    {
        path: '/settings/regions-page',
        element: <RegionsPage />
    },
    {
        path: '/settings/users-page',
        element: <UsersPage />
    },
    {
        path: '/settings/distribution-page',
        element: <DistributionPage />
    },
    {
        path: '/settings/request-tupes-page',
        element: <RequestTypeListPage />
    },
    {
        index: true,
        element: <TicketsPage />
    },
    {
        path: '/ticket/:id',
        element: <TicketPage />
    },
    {
        path: '/client',
        element: <ClientsPage />
    },
    {
        path: '/client/:id',
        element: <ClientPage />
    }
];

export default AppRoutes;
