export const stageName = (stageId) => {
    switch (stageId) {
        case 0:
            return "Новый";
        case 1:
            return "Сбор данных";
        case 2:
            return "Ожидание ответа на КП";
        case 100:
            return "Завершен";
    }
};

export const requestTypeName = (requestTypeId) => {
    switch (requestTypeId) {
        case 0:
            return "Каско";
        case 1:
            return "Осаго";
        case 2:
            return "ИФЛ";
        case 3:
            return "Ипотека";
        case 4:
            return "КБМ";
        case 5:
            return "Проверка Осаго на Подлинность";
        case 6:
            return "Инструкции";
        case 7:
            return "Европротокол";
        case 100:
            return "Чат";
    }
};

export const requestTypeNameMenu = (requestTypeId) => {
    switch (requestTypeId) {
        case "kasko":
            return "Каско";
        case "osago":
            return "Осаго";
        case "ifl":
            return "ИФЛ";
        case "ipoteka":
            return "Ипотека";
        case "kbm":
            return "КБМ";
    }
};
////Варианты оформления
//export enum DesignOptionType {
//    InsuranceOffice,    //В офисе страховой компании
//    CustomLocation,     //В любое удобное место и время
//    OurOffice           //наш офис
//}

////Варианты доставки
//export enum DeliveryType {
    
//}

////Варианты оплаты
//export enum PaymentType {
//    CardCustomLocation, //Оплата картой выездному специалисту
//    CashCustomLocation, //Оплата наличкой выездному специалисту
//    InsuranceCashDesk,  //Оплата в кассу страховой компании
//    BillPayment         //Оплата по выставленному счету от СК на расчётный счёт страховой компании в любом банке или в онлайн банке, в том числе если выбран вариант доставки.
//}
export const designOptionTypeName = (type) => {
    switch (type) {
        case 0:
            return "В офисе страховой компании";
        case 1:
            return "В нашем ближайшем офисе";
        case 2:
            return "Доставка в любое удобное время и место (бесплатно)";
        default:
            return "Не выбран вариант оплаты";
    };
};

export const paymentTypeName = (type) => {
    switch (type) {
        case 0:
            return "В кассу страховой компании";
        case 1:
            return "Наличными выездному специалисту";
        case 2:
            return "По карте выездному специалисту";
        case 3:
            return "По выставленному счету от СК на расчётный счёт страховой компании в любом банке или в онлайн банке, в том числе если выбран вариант доставки.";
        default:
            return "Не выбран вариант оформления";
    };
};

export const ticketStatus = (status) => {
    switch (status) {
        case 0:
            return <span className="status-new">Новая</span>;
        case 1:
            return <span className="status-colect-data">Сбор данных</span>;
        case 2:
            return "Клиент ожидает КП";
        case 3:
            return <span className="status-waiting-kp">Ожидаем выбора КП</span>;
        case 4:
            return "Выбрал предложение";
        case 5:
            return "Клиента не интересует";
        case 6:
            return "Клиент думает";
        case 7:
            return "Клиент выбрал КП";
        case 8:
            return "Клиент выбрал способ доставки";
        case 9:
            return "Клиент выбрал способ оплаты";
        case 10:
            return "Ожидается оплата";
        case 11:
            return "Ожидается доставка";
        case 12:
            return "Клиент ждет звонка";
        case 13:
            return "Страховая компания отказала";
        case 14:
            return "Отправка документов страховой";
        case 15:
            return "Ожидаем ответа страховой компании";
        case 100:
            return "Клиент получил полис";
        default:
            return "Не определен";
    };
};

export const clientTicketStatus = (status) => {
    //console.log("STAGE: ", status, status = (0 || 1 || 2));
    switch (status) {
        case 0 || 1 || 2:
            return "В обработке";
        case 3 || 4:
            return "Ожидает выбора КП";
        case 5:
            return "Меня не интересует";
        case 6:
            return "Думаю";
        case 7:
            return "Выбрал КП";
        case 8:
            return "Выбрал способ доставки";
        case 9:
            return "Выбрал способ оплаты";
        case 10:
            return "Ожидается оплата";
        case 11:
            return "Ожидается доставка";
        case 12:
            return "Жду звонка";
        case 13:
            return "Страховая компания отказала";
        case 14:
            return "Отправка документов страховой";
        case 15:
            return "Ожидаем ответа страховой компании";
        case 100:
            return "Получен страховой полис";
        default:
            return "Не определен";
    };
};

export const ticketOfferStatusName = (status) => {
    switch (status) {
        case 0:
            return "Шаблон";
        case 1:
            return "Предложено клиенту";
        case 2:
            return "Отклонено клиентом";
        case 3:
            return "Отклонено менеджером";
        case 4:
            return "Клиент принял предложение";
        case 5:
            return "Удалено";
        default:
            return "Шаблон";
    };
};

export const fileNameByFileType = (type) => {
    switch (type) {
        case 0:
            return "Паспорт второго застрахованного (стр. 2-3 и стр. с актуальной регистрацией)";
        case 1:
            return "Паспорт страхователя (стр. 2-3 и стр. с актуальной регистрацией)";
        case 2:
            return "Выписка из ЕГРН";
        case 3:
            return "Договор страхования прошлого года (при переходе из другой страховой компании)";
        case 4:
            return "Правоустанавливающий документ (договор купли-продажи, договор дарения ДДУ и т.п.)";
        case 5:
            return "Акт приема-передачи/передаточный акт";
        case 6:
            return "Выписка из домовой книги/Единый жилищный документ";
        case 7:
            return "Кредитный договор (в электронном виде или стр. 1-4)";
        case 8:
            return "Правоустанавливающий документ на предыдущего собственника (если сделка была менее трех лет назад)";
        case 9:
            return "Отчёт об оценке";
        case 10:
            return "ПТС";
        case 11:
            return "СТС";
        case 12:
            return "Водительские удостоверение";
        case 13:
            return "Осаго описание";
        case 14:
            return "Каско описание";
        case 15:
            return "ИФЛ описание";
        case 16:
            return "Ипотека описание";
        case 17:
            return "Паспорт страхователя (стр. 2-3 и стр. с актуальной регистрацией)";
        case 18:
            return "Действующий полис (при наличии)";
        case 19:
            return "Дополнительный файл заявки";
        case 20:
            return "Фотографии объекта страхования";
        case 21:
            return "Паспорт продавца (все страницы)";
        case 22:
            return "Справки ПД/ПНД";
        case 23:
            return "Доверенность на продажу";
        default:
            return "Не удалось определить название файла!!!!";
    };
}

export const buildingTypes = [
    {
        value: "Квартира",
        label: "Квартира"
    },
    {
        value: "Комната",
        label: "Комната"
    },
    {
        value: "Жилой дом",
        label: "Жилой дом"
    },
    {
        value: "Апартаменты",
        label: "Апартаменты"
    },
    {
        value: "Таунхаус",
        label: "Таунхаус"
    },
    {
        value: "Другое",
        label: "Другое"
    },
]

export const objectOfInsuranceTypes = [
    {
        value: 0,
        label: "Квартира",
    },
    {
        value: 1,
        label: "Комната",
    },
    {
        value: 2,
        label: "Жилой дом",
    },
    {
        value: 3,
        label: "Апартаменты",
    },
    {
        value: 4,
        label: "Таунхаус",
    },
    {
        value: 5,
        label: "Другое",
    }
]

export const materialOptions = [
    {
        value: "Смешанный",
        label: "Смешанный"
    },
    {
        value: "Горючий",
        label: "Горючий"
    },
    {
        value: "Негорючий",
        label: "Негорючий"
    },
];

export const getObjectOfInsuranceTypeNameById = (value) => {
    return objectOfInsuranceTypes[value]?.name;
}

export const getNextId = (items = []) => {
    //console.log('count items', items.length);
    let maximum = 0;

    items.forEach((item) => {
        if (item.id > maximum)
            maximum = item.id;
    });

    return maximum + 1;
};

export const toCurrency = (n, curr, LanguageFormat = undefined) => Intl.NumberFormat(LanguageFormat, {
    style: 'currency',
    currency: curr,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
}).format(n);

export const MDLCurrency = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'MDL',
});

export const getJsonFilesFromArrayTypes = (types = [], required = false, multiple = true) => {
    const initialFile = {
        refId: null,
        uploaded: false,
        fileName: null,
        fileId: null
    };

    const initialFileData = {
        fileType: null,
        multiple: multiple,
        required: required,
    };

    return;
}